<template>
<div id="page" class="home-page-index">
  <iframe src="../common/index.html" style="width: 100%;height: 100%;" frameborder="0" />
</div>
</template>

<script>
// 首页 - 嵌入大屏
export default {
  setup() {
    return {}
  }
}
</script>

<style lang="scss" scoped>
#page {
  overflow: hidden !important;
}
</style>
